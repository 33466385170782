// appear
.fade-appear {
    opacity: 0;
    z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

// enter
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in-out;
}

// exit
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}
.fade-exit-done {
  opacity: 0;
}




// Fade with delay 
.fade-delay-appear {
    opacity: 0;
    z-index: 1;
}
.fade-delay-appear.fade-delay-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

// enter
.fade-delay-enter {
  opacity: 0;
  z-index: 1;
}
.fade-delay-enter.fade-delay-enter-active {
    opacity: 1;
    transition: opacity 500ms linear 500ms;
}

//exit
.fade-delay-exit {
    opacity: 1;
}
.fade-delay-exit.fade-delay-exit-active {
    opacity: 0;
    transition: opacity 1000ms linear;
}
.fade-delay-exit-done {
  opacity: 0;
}
