footer {
  height: 20vh;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  padding: 0 3.5vh;
  align-items: center;
  position: relative;
  z-index: 10;
  box-shadow: rgba(150,150,150,0.1) 0 -0.01vh 20px;
  gap: 3rem;
}


.clock {
  text-align: right;
  flex-shrink: 0;

  p {
    margin:0;
  }
}

.time {
  font-size: 10.5vh;
  //font-family: $tercial-font;
  font-weight: 400;
  line-height: 0.7;
}

.date {
  font-size: 4vh;
  line-height: 1.4;
  font-weight: 300;
}

.becikelj {
  display: flex;
  text-align: center;
  //align-items: center;
  p {
    font-size: 8.8vh;
    font-weight: 400;
    line-height: 0.8;
    margin:0;
    //font-family: 'Roboto Condensed', sans-serif;
    margin-left: 2.5vh;
    text-align: right;

    span {
      font-size: 0.38em;
      font-weight: 300;
      display: block;
      //font-family: 'Roboto', sans-serif;
      margin-top: 1.5vh;
    }
  }
}

.becikeljIcon {
  height: 10vh;
  svg {
    height: 100%;
    width:auto;
  }
}

.busIcon {
  display: flex;
  flex-direction: column;
  text-align: center;

  svg{
    height: 7vh;
    width: auto;
  }
  h2, p {
    margin:0;
    text-align: center;
    font-family: $terciary-font-cond;
  }
  h2 {
    font-size: 3.6vh;
    font-weight: 700;
    margin-top: 0.5vh;
    text-transform: uppercase;
    line-height: 1.4;
  }
  p {
    font-size: 3vh;
    font-weight: 300;
    line-height: 0.8;
    font-family: $terciary-font-cond;
  }
}


.trola {
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 2rem;
}

.timetable {
  height: 100%;
  margin:0;
  margin-left: 3vh;
}



.busNumber {
  display: block;
  //color: $white;
  font-size: 3.2vh;
  font-weight: $bold;
  //background-color: $gray;
  width: 7vh;
  height: 7vh;
  border-radius: 50%;
  border:solid 0.4vh;
  text-align: center;
  line-height: 6.2vh;
  margin-right: 1.5vh;
  margin-bottom: 1vh;
}

.busName {
  font-size: 3vh;
  font-family: 'Roboto Condensed', sans-serif;
  //width: 15vh;
  line-height: 1.2;
  padding-right: 1vh;
  font-weight: 600;
}

.busTime {
  font-size: 4.4vh;
  width: 14vh;
  margin-right: 2vh;
  text-align: right;

  span {
    font-size: 0.7em;
  }
}

.timetable tr td:nth-child(3){
    font-weight: 600;
}

.timetable tr:nth-child(1) {
  padding-bottom: 2vh
}

.line {
  width: 3px;
  height: 70%;
  background-color: $medium-gray;
  margin:0 4vh;
}











