.showcase {
	height: 100%;
	width: 100%;
	background-color: $black;
}

.content-container {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;

	img, .introSlide {
		display: block;
		position: absolute;
		width:100%;
		min-height: 100%;
		top:0; left:0;
	}

	.introSlide {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4.5vh;

		h1 {
			margin:0;
			font-size: 9vh;
			font-family: $serif;
			font-weight: 700;
			text-align: center;
			//color: $grafika;
		}

		h2 {
			margin:0;
			font-size: 5vh;
			font-family: $serif;
			font-weight: 300;
			text-align: center;
		}


		p {
			font-size: 3vh;
			text-align: center;
			margin-bottom: 0.2em;
		}
	}
}


iframe {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80%;;
}




	
