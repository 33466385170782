html {
  font-family: $primary-font;
  width: 100vw;
  height: 100vh;
}

body {
  background-color: $light-gray;
  color: $black;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: none;
}

.app {
  display: flex;
  flex-direction: column;
}

main {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

//  --------------------------------------
//  ---------- TYPOGRAPHY ----------------
//  --------------------------------------

// font: font-style font-variant font-weight font-size/line-height font-family;

$line-ratio: 1.6;
$space-after: 1em;
$base-font-size: 16px;

h1 {
  font-size: 1.2em;
  text-align: center;
  font-weight: $bold;
  letter-spacing: 1px;
  margin-top: 2em;
  margin-bottom: 1.5em;
}

h2 {
  font-weight: $normal;
  font-size: 1.2em;
  text-align: center;
}

h3 {
  font-weight: $normal;
  font-size: 1em;
}

p,
figcaption,
li {
  font-size: 1em;
  line-height: $line-ratio;
  color: $black;
  margin: 0;
  margin-bottom: $space-after;
  font-weight: $normal;
  font-family: $primary-font;
  /* Better Font Rendering =========== */
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

li {
  margin-bottom: 0.5em;
  line-height: 1.4;
}

p.cap {
  text-transform: uppercase;
}

// define styles for all H tags
#{headings()} {
  font-family: $secondary-font;
  line-height: 1.2;
  font-weight: $normal;
  color: $black;
  //letter-spacing: ps-letter-space(20);
  margin-bottom: 0.8em;
  margin-top: 1.4em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 + h2,
h2 + h3,
h1 + h3 {
  margin-top: 0;
}

strong {
  font-weight: $bold;
}

em {
  font-weight: $normal;
  font-style: italic;
}

a {
  text-decoration: none;
  color: $gray;

  &:hover {
    color: $light-gray;
  }
}
