/*
 * Box Sizing
 */

 /* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}


html {
	font-size: 100%;
}

html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
	-ms-text-size-adjust: 100%;
}

::selection {
  background: $gray; /* WebKit/Blink Browsers */
  color: $white;
}
::-moz-selection {
  background: $gray; /* Gecko Browsers */
  color: $white;
}

::-ms-clear { display: none; }
::-ms-reveal {
   display: none;
}

/* Remove controls from Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Remove controls from Safari and Chrome */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0; /* Removes leftover margin */
}


/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
}

/*
 *	DEFAULT STYLES 
 */

ul ul li {
	font-size: 1em;
}

img {
	max-width: 100%;
	height: auto;
	margin-bottom: $space-after;
}

:focus, :active {
	outline: 0;
}


blockquote, q {
	quotes: "" "";

	&:before,
	&:after {
		content: "";
	}
}

table {
	margin: 0 0 $space-after;
  //width: 100%;
}

iframe {
  border:none;
}

//disable mobile browser click/touch/tap feedback box
* { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* -------------------------------- 

Reset Input Style

-------------------------------- */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background: rgba($gray, 0.5) !important;
}


input[type="search"]::-ms-clear {
	/* removes close icon - IE */
    display: none;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
