// Color scheme for project

$black        : #101010;
$gray         : #909090;
$medium-gray  :	#f3f3f3;
$light-gray   : #f8f8f8;

$white        : #fff;

$error        : #dc5151;
$success      : #349657;

$grafika      : #006BA6;
$tekstil      : #6BA539;
$oblikovanje  : #A20067;
$ntf          : #CB333B;

// Fonts for project
/*
$primary-font		   : 'Roboto', sans-serif;
$secondary-font		 : 'Roboto Condensed', sans-serif;
*/

$primary-font       : 'Open Sans', sans-serif;
$secondary-font     : 'Open Sans Condensed', sans-serif;
$terciary-font      : 'Roboto', sans-serif;
$terciary-font-cond : 'Roboto Condensed', sans-serif;
$serif              : 'Roboto Slab', serif;

$light		    : 300;
$normal		    : 400;
$bold		      : 700;


// Shadows - use: @include single-text-shadow;
$default-text-shadow-color		  : $gray;
$default-text-shadow-h-offset	  : 1px;
$default-text-shadow-v-offset	  : 1px;
$default-text-shadow-blur		    : 2px;
$default-text-shadow-spread		  : true;

$largex	: (1680/16) * 1em; 
$large	: (1024/16) * 1em; // 64
$medium	: (768/16) * 1em; //
