.defaultScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    
	.defaultLogo {
        width: 40%;
    }
}

	
