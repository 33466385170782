.schedule {
	/*column-count: 3;
	column-gap: 5vw;*/
	height: 100%;
	width: 100%;
	padding: 4.5vh;
	padding-bottom: 1vh;
	
	h2 {
		font-weight: 300;
		text-align: left;
		//text-transform: uppercase;
		font-size: 5.5vh;
		color: $gray;
		font-family: $secondary-font;
		margin-bottom: 3vh;
		margin-top: 0;
		line-height: 1
	}

	h3 {
		font-size: 3.5vh;
		font-weight: 600;
		line-height: 1.1;
		margin: 0;
		margin-bottom: 0.25vh;
		span {
			font-weight: 700;
		}
	}

	p {
		font-size: 3.25vh;
		margin: 0;
		line-height: 1.4;
	}

	ul {
		height: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
		grid-auto-flow: column;
		align-items: start;
		align-content: space-between;
		margin:0;
		list-style: none;
		padding:0;

		li {
			border-left: 0.8vh solid;
			padding-left: 2vh;
			padding-right: 3vh;
		}
	}
}

	
